import React, { Component } from 'react'

class Skills extends Component {
  render() {
    return (
        <div>
            <h1>Skills</h1>
            <p>
                No skills published yet.
            </p>
        </div>
    )
  }
}

export default Skills