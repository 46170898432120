import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Logo from './logo.svg';
import ImgWIP from './undraw_in_progress_ql66.svg'

import About from './About';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Bio from './Bio'
import Work from './Work'
import Skills from './Skills'
import Hobbies from './Hobbies'

import ImgBio from './undraw_short_bio_e5pg.svg'
import ImgWork from './undraw_work_time_lhoj.svg'
import ImgSkills from './undraw_percentages_0rur.svg'
import ImgHobbies from './undraw_skateboard_nmbb.svg'

import Card from './Card';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="main">
          <div className="nav">
            <div className="logoContainer">
              <img src={Logo} alt="logo" width="30px"></img>
              <div className="logoText">
                <Link to="/">Ilie Bogdan</Link>
              </div>
            </div>
            <div className="navEmpty">

            </div>
            <div className="menu">
                <Link className="menuItem" to="/">
                  Home
                </Link>
                <Link className="menuItem" to="/about">
                  About
                </Link>
                <Link className="menuItem" to="/portfolio">
                  Portfolio
                </Link>
                <Link className="menuItem" to="/contact">
                  Contact
                </Link>
            </div>
          </div>
          <div className="content">
            <Switch>
                <Route path="/about">
                  <div className="cards">
                    <Card 
                      image={ImgBio} 
                      title={"Bio"} 
                      desc={"Very curious in nature, I want to know how things work. Highly passionate about technology..."}
                      link="/bio"
                    />
                    <Card 
                      image={ImgWork} 
                      title={"Work"} 
                      desc={"A technical writer since 2014 with a strong technical background and understanding..."}
                      link="/work"
                    />
                  </div>
                  <div className="cards">
                    <Card 
                      image={ImgSkills} 
                      title={"Skills"} 
                      desc={"Being curious and thirsty for knowledge has allowed me to develop a broad range of skills..."}
                      link="/skills"
                    />
                    <Card 
                      image={ImgHobbies} 
                      title={"Hobbies"} 
                      desc={"Curiosity roams free in my spare time as well, trying out numerous hobbies and free-time activities..."}
                      link="/hobbies"
                    />
                  </div>
                </Route>
                <Route path="/portfolio" component={Portfolio} />
                <Route path="/contact" component={Contact} />
                <Route path="/" exact component={Home} />
                <Route path="/bio" component={Bio}/>
                <Route path="/work" component={Work}/>
                <Route path="/skills" component={Skills}/>
                <Route path="/hobbies" component={Hobbies}/>
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

function Home() {
  return (
    <>
      <h1>Home page</h1>
      <h3>Site Under Construction</h3>
      <img src={ImgWIP} alt="site under construction image" width="300px"/>
      <p>If you want to get in touch, use the <Link to='/contact'>contact form</Link>.</p>
    </>
)
}

export default App