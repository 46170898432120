import React, { Component } from 'react'

//import Contact from './Contact';

class Portfolio extends Component {
  render() {
    return (
        <React.Fragment>
            <h1>Portfolio</h1>
            Nothing here yet
        </React.Fragment>
    )
  }
}

export default Portfolio