import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Bio from './Bio'


class Card extends Component {
    constructor(){
        super();
        this.state = {isHovered: false};
        this.handleHover = this.handleHover.bind(this);
    }
    handleHover(){
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }));
    }
  render() {
      const pClass = this.state.isHovered ? "shownCard": "hiddenCard"
      const iClass = this.state.isHovered ? "smaller" : ""
      const hClass = this.state.isHovered ? "hiddenMark": "shownMark"
    return (
        <>
            <div className="cardContainer">
                <div className="cardContent" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
                    <div className="cardImg">
                        <img 
                            src={this.props.image} 
                            alt={this.props.title + " section image"} 
                            width="200px"
                            height="150px"
                            className={iClass}
                        />
                    </div>
                    <h4>{this.props.title}</h4>
                    <p className={pClass}>{this.props.desc}</p>
                    <Link to={this.props.link} className={pClass}>Read more...</Link>
                    <p className={hClass}>&#94;</p>
                </div>
            </div>
            <div className="flexBreak"></div>
        </>
    )
  }
}

export default Card