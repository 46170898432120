import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import { Button, FormFeedback, Form, FormGroup, Label, Input } from 'reactstrap'
import Fb from './facebook.svg'
import Insta from './instagram.svg'
import LinkedIn from './linkedin.svg'
import './contact.css';

//import Contact from './Contact';


class Contact extends Component {

    
    state = {
        name: '',
        email: '',
        subject: '',
        message: '',
    }

    handleSubmit(e) {
        e.preventDefault()
        const { name, email, subject, message } = this.state

        const emailField = document.getElementById('emailField').value;
        const nameField = document.getElementById('nameField').value;
        const subjectField = document.getElementById('subjectField').value;
        const messageField = document.getElementById('messageField').value;
        const x = document.getElementById('note');

        let templateParams = {
            from_name: email,
            to_name: 'ilie06@gmail.com',
            subject: subject,
            message_html: message,
            }
        
        if (emailField !== "" && nameField !== "" && subjectField !== "" && messageField !== "") {
            emailjs.send(
                'gmail',
                'template_iliebogdan_test',
                templateParams,
                'user_DK1GGSC0WrcVyMX2TUjDI'
            )
            this.resetForm()
            x.innerText = "E-mail sent!";
            if (x.className==="hidden") {
                x.className="emailConfirmation";
                setTimeout(function () {
                    x.className="hidden";
                }, 5000);
            }
        }
        else {
            x.innerText = "Fill in all fields!";
            x.className = "emailFailure";
            setTimeout(function () {
                x.className="hidden";
            }, 5000);
        }
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    
  render() {
    return (
        <>
          <h1>Contact me</h1>
          <p>Send me an e-mail and I'll reply as soon as I can</p>
          <Form onSubmit={this.handleSubmit.bind(this)} className="container">
            <FormGroup controlId="formBasicEmail">
              <Label className="text-muted">Your Email Address</Label>
              <Input
                id="emailField"
                type="email"
                name="email"
                value={this.state.email}
                className="text-primary"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Enter email"
              />
            </FormGroup>
            <FormGroup controlId="formBasicName">
              <Label className="text-muted">Your Name</Label>
              <Input
                id="nameField"
                type="text"
                name="name"
                value={this.state.name}
                className="text-primary"
                onChange={this.handleChange.bind(this, 'name')}
                placeholder="Name"
              />
            </FormGroup>
            <FormGroup controlId="formBasicSubject">
              <Label className="text-muted">Subject</Label>
              <Input
                id="subjectField"
                type="text"
                name="subject"
                className="text-primary"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Subject"
              />
            </FormGroup>
            <FormGroup controlId="formBasicMessage">
              <Label className="text-muted">Message</Label>
              <Input
                id="messageField"
                type="textarea"
                name="message"
                className="text-primary"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
              />
            </FormGroup>
            <Button variant="primary" type="submit">
              Submit
            </Button>
            <p id="note" className="hidden">Email sent.</p>
          </Form>
          <h3>Get in Touch via Social Media</h3>
          <p>If you prefer social media, let's connect through:</p>
          <div className="socialContainer">
            <a href="https://www.facebook.com/nosthrillz" target="_blank" rel="noopener noreferrer">
              <img src={Fb} alt="facebook icon" width="50px" />
            </a>
            <a href="https://www.instagram.com/nosthrillz" target="_blank" rel="noopener noreferrer">
              <img src={Insta} alt="instagram icon" width="50px" />
            </a>
            <a href="https://www.linkedin.com/in/ilie-bogdan-mihai" target="_blank" rel="noopener noreferrer">
              <img src={LinkedIn} alt="linkedin icon" width="50px" />
            </a>
          </div>
      </>
    )
  }
}

export default Contact