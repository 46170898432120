import React, { Component } from 'react'

class Work extends Component {
  render() {
    return (
        <div>
            <h1>Work</h1>
            <p>
                No work history yet.
            </p>
        </div>
    )
  }
}

export default Work