import React, { Component } from 'react'
import Bio from './Bio'
import Work from './Work'
import Skills from './Skills'
import Hobbies from './Hobbies'

import ImgBio from './undraw_short_bio_e5pg.svg'
import ImgWork from './undraw_work_time_lhoj.svg'
import ImgSkills from './undraw_percentages_0rur.svg'
import ImgHobbies from './undraw_skateboard_nmbb.svg'

import Card from './Card';

//import Contact from './Contact';



class About extends Component {
  render() {

    return (
        <>
            <div className="cards">
              <Card 
                image={ImgBio} 
                title={"Bio"} 
                desc={"Very curious in nature, I want to know how things work. Highly passionate about technology..."}
                link={Bio}
              />
              <Card 
                image={ImgWork} 
                title={"Work"} 
                desc={"A technical writer since 2014 with a strong technical background and understanding..."}
              />
            </div>
            <div className="cards">
              <Card 
                image={ImgSkills} 
                title={"Skills"} 
                desc={"Being curious and thirsty for knowledge has allowed me to develop a broad range of skills..."}
              />
              <Card 
                image={ImgHobbies} 
                title={"Hobbies"} 
                desc={"Curiosity roams free in my spare time as well, trying out numerous hobbies and free-time activities..."}
              />
            </div>
        </>
    )
  }
}

export default About