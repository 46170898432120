import React, { Component } from 'react'

class Hobbies extends Component {
  render() {
    return (
        <div>
            <h1>Hobbies</h1>
            <p>
                No hobbies here yet.
            </p>
        </div>
    )
  }
}

export default Hobbies