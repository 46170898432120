import React, { Component } from 'react'


class Bio extends Component {
  render() {
    return (
        <div>
            <h1>Bio</h1>
            <p>
                {/*Ilie Bogdan is an established Technical Writer, highly passionate about technology.*/}
                Nothing written yet.
            </p>
        </div>
    )
  }
}

export default Bio